import { Link } from "react-router-dom";
import React from "react";
import Breadcrumb from "../../Breadcrumb/Main";
import { Email } from "react-obfuscate-email";
import Seo from "../../../utils/seo";

const Service = () => {
  function handleScrollTo(item) {
    const element = document.getElementById(item);
    element.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <Seo pageTitle="Technologies" id="tech" />
      <Breadcrumb
        title={"Home"}
        subtitle={"Technology"}
        common={"Our Technologies"}
      />

      <section className="service-details faq-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-sidebar">
                <div className="service-sidebar__category">
                  <h3>Quick Menu</h3>
                  <ul className="list-unstyled service-sidebar__category-list">
                    <li>
                      <Link onClick={() => handleScrollTo("1")} to="#">
                        The Tera Data Engine
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("2")} to="#">
                        Exfin
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("3")} to="#">
                        Tera Intel Platform
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="service-sidebar__call">
                  <div
                    className="service-sidebar__call-bg"
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL +
                        // "/assets/images/partnerships/call-us-today.jpg"
                        "/assets/images/partnerships/call-us-today.jpg"
                        // "/assets/images/services/service-sidebar-1-1.jpg"
                      })`,
                    }}
                    // style={{ backgroundImage: `url(${SSBAR1})` }}
                  ></div>
                  <i className="tera-icon-tech-support"></i>
                  <h3>
                    <Link href="tel:+35319039642">+353 1 903 9642</Link>
                  </h3>
                  <Link href="#">
                    <Email email="info@teracapital.io" className="text-left">
                      info@teracapital.io
                    </Email>
                  </Link>
                  <p>
                    Give us a call to learn about the technologies we use to
                    acquire unique insights on energy assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details__content">
                <div id="dobusiness" className="service-details__image">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/technologies/technologies-1.jpg"
                    }
                    className="img-fluid"
                    alt="Tera Data Engine"
                  />
                </div>
                <h3 className="mt-30">The Tera Data Engine</h3>

                <p>
                  <a href="https://terapower.io">Tera Power</a> is an IT firm
                  specialized in developing deep tech solutions related to the
                  power grid. Tera Power's team is responsible for developing
                  the Tera Data Engine, as well as a suite of algorithms for
                  aggregating data from the power grid at scale. The Tera Data
                  Engine powers the most comprehensive mapping system of the
                  power grid ever made. The Tera technology stack includes a
                  series of tools for analyzing the market at scale, monitoring
                  market participant activities, valuing assets and connecting
                  with other energy professionals.
                </p>
                <ul className="list-unstyled about-two__list service-details__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    3+ million renewable energy generators in 130 countries.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    20k+ renewable energy project developers.
                  </li>
                  <li id="2">
                    <i className="fa fa-check-circle"></i>
                    Granular data on land rights, planning permits, connection
                    points, and PPAs.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Mapping system with filters for highlighting sensitive
                    ecosystems, and communities.
                  </li>
                </ul>

                <h3 className="mt-30">
                  The Exfin Financial Modelling Software
                </h3>
                <div className="service-details__image mt-30">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/technologies/exfin.jpg"
                    }
                    className="img-fluid"
                    alt="Exfin software"
                  />
                </div>

                <p className="mt-30">
                  Tera Capital's co-founders were responsible for the
                  development of the{" "}
                  <a href="https://exfinsoftware.com">Exfin Software</a>. Exfin
                  is the first complete renewable energy financial platform
                  designed for teams to collaborate in real time and gain
                  actionable insights about renewable energy projects. As a
                  single source of digital truth, exfin software helps to
                  explore the unknowns, de-risk, and ultimately better quantify
                  and build renewable energy projects, from the outset. Exfin is
                  a renewable energy financial modelling solution that offers
                  reliable, and auditable results for the wind, solar, wave or
                  tidal industry.
                </p>
                <ul className="list-unstyled about-two__list service-details__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Project’s cashflow generation in seconds
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Transparent KPI analysis
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Project output analysis
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Cost reduction pathways analysis
                  </li>
                  <li id="3">
                    <i className="fa fa-check-circle"></i>
                    Resource use maximization
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Comparison of asset financial models at scale
                  </li>
                </ul>
                <h3 className="mt-30">The Tera Intel Platform</h3>
                <div className="service-details__image mt-30">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/technologies/tera-intel.png"
                    }
                    className="img-fluid"
                    alt="Tera Intel platform"
                  />
                </div>

                <p className="mt-30">
                  <a>Tera Intel</a> is a market
                  intelligence platform that allows Tera Capital&apos;s analysts
                  to access unique insights about the power grid, power plants,
                  projects under developments and the various companies
                  involved. The platform features a suite of tools for
                  identifying, analyzing and evaluating projects or operating
                  assets. It also provides detailed information on companies
                  controlling these assets, and key company executives or
                  decision makers. The platform covers all markets and
                  continents.
                </p>
                <ul className="list-unstyled about-two__list service-details__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Provides us with a bird&apos;s eye view of the energy market
                    and its participants
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Allows us to search for projects across geographies
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Evaluate projects&apos; relevance in a wider context
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Analyze local electricity demand, grid capacity, protected
                    natural zones, etc.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Compare projects&apos; specific KPIs with millions of other
                    assets
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
